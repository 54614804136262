@import "../../scenes/LandingPage/LandingPage";

$header-height: 64px;
$menu-item-border: 2px;

#header,
header {
  transition: all 0.3s;
  background: #fff;
  box-shadow: none;
  position: relative;
  z-index: 10;
  overflow: hidden;
  & #logo {
    overflow: hidden;
    color: $primary;
    float: left;
    text-decoration: none;
    white-space: nowrap;
    transition: padding .3s ease-in-out;
    img {
      height: 50px;
      margin-right: 16px;
    }
    a {
      color: $primary
    }
    img+img {
      height: 16px;
      position: relative;
      top: 1px;
    }
  }

  a:hover {
    text-decoration: none;
  }

  @media (max-width: 800px) {
    .ant-menu-item {
      padding: 0 10px;
    }
  }
}

.home {
  #header,
  header {
    box-shadow: none;
    max-width: 1200px;
    width: 100%;
    margin: 20px auto 0;
    padding: 0 24px;
    #logo {
      padding: 0;
    }
  }
}

.ant-menu-horizontal {
  float: right;
}
.ant-menu-item > .btn-link {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-item {
  padding: 0 15px;
}
.nav-phone-icon {
  display: none;
  position: absolute;
  right: 30px;
  top: 25px;
  z-index: 1;
  width: 16px;
  height: 22px;
  cursor: pointer;
}

.nav-phone-icon {
  display: block;
}

.nav-phone-icon:before {
  content: "";
  display: block;
  border-radius: 2px;
  width: 16px;
  height: 2px;
  position: absolute;
}